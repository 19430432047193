// Breakpoints
@mixin s { @media (max-width: 767px) { @content; } }
@mixin m { @media (max-width: 1023px) { @content; } }
@mixin l { @media (max-width: 1120px) { @content; } }
@mixin desktop-only { @media (min-width: 1024px) { @content; } }

// Colors
$color-black: #000;
$color-white: #fff;

@import 'typography';

@import '../components/bubble';
@import '../components/input';
@import '../components/auth';
@import '../components/error-screen';

body {
  margin: 0;
  padding: 0;
  background-color: $color-black;
  color: $color-white;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar { display: none }
}

.wordy__messages {
  padding-bottom: 90px;
}