.input {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgb(253, 253, 253);
  width: 100%;
  padding: 10px 8px 8px;
  box-sizing: border-box;
  color: black;
  display: flex;
}

.input form {
  display: flex;
}

input[type=text],
input[type=password] {
  border: 1px solid #333;
  border-radius: 0 !important;
  outline: none !important;
  -webkit-appearance: none;
  height: 30px;
  line-height: 30px;
  background-color: rgba(white, $alpha: 0.7);
  width: 100%;
  box-sizing: border-box;
}

.input__options {
  margin-top: 7px;
}

.input__options__left {
  font-size: 12px !important;
}

.input__submit {
  border: none;
  border-radius: 0;
  font-family: FontAwesome;
  background: black;
  color: white;
  font-size: 16px;
  padding: 0 8px !important;
  -webkit-appearance: none;
  display: block;
  margin: 0;
  text-align: center;
}

.auth__logout {
  display: none;
  .wordy--logged-in & { display: inline; }
}

.auth__change {
  display: inline;
  .wordy--logged-in & { display: none; }
}

.input__avatar {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  border-radius: 8px;

  .input--logged-in & { cursor: pointer; }
}

.input__right {
  flex: 1;
}

.input__left {
  position: relative;
}

.input__left__loading {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  background: rgba(#000000, 0.7);
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 50px;
    height: 50px;
  }

  .input--uploading & { display: block; } 
}

#file-input {
  display: none;
}