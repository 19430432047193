.auth__wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.8);
  left: 0;
  top: 0;
}

.auth {
  padding: 10px;
  background-color: rgb(253, 253, 253);
  color: black;
  width: 280px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.auth__title {
  font-weight: bold;
  letter-spacing: 1.6px;
  font-size: 16px;
}

.auth__subtitle {
  font-weight: bold;
  letter-spacing: 1.6px;
  margin-top: 12px;
}

.auth p {
  margin: 5px 0 10px;
}

.auth input:nth-child(4),
.auth input:nth-child(5) {
  border-top: none;
}

.auth__row {
  display: flex;

  button {
    height: 25px;
    line-height: 24px;
    margin: 0;
  }
}

.auth input {
  height: 25px;
  line-height: 26px;
}

button {
  -webkit-appearance: none;
  background: black;
  color: white;
  border: none;
  border-radius: none;
  height: 22px;
  line-height: 21px;
  font-size: 10px;
  letter-spacing: 2px;
  margin-top: 4px;
  margin-right: 4px;
  text-transform: uppercase;
}


.auth__buttons {
  display: flex;

  div {
    font-size: 10px;
    padding-top: 3px;
    flex: 1;
    text-align: right;
  }
}

.auth .error {
  color: red;
}