.bubble {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.bubble__body {
  display: flex;
}

.bubble__body__photo {
  width: 40px;
  margin-right: 8px;
  img { width: 100%; display: block; border-radius: 8px; } 
}

.bubble__body__message {
  flex: 1;
  padding-right: 8px;
}

.bubble__body__message__nickname {
  font-weight: bold;
}

.bubble__body__message__image {
  border-radius: 12px;
  margin-top: 8px;
  width: 100%;
  max-width: 360px;
}