// Configure rendering
body, input, button {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Varela Round', Helvetica, Arial, sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
}

// Reset
h1, h2, h3, h4, h5, h6 { margin: 0; padding: 0; font-weight: normal; font-size: 16px; }

a { color: inherit; }