.error-screen__wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.8);
  left: 0;
  top: 0;
}

.error-screen {
  padding: 10px;
  background-color: red;
  color: black;
  width: 280px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.error-screen__title {
  font-weight: bold;
  letter-spacing: 1.6px;
  font-size: 16px;
}

.error-screen__body {

}